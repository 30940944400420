<template>
	<div class="branch payment">
		
		<PickerWeeks
			class="picker mt-5"
			@click="PickerWeeks"
		>
		</PickerWeeks>
		
		<div
			class="pa-5"
		>
			<v-row	
				v-if="false"
			>
				<v-col>
					총 결제 건수
				</v-col>
				<v-col
					class="text-right"
				>
					{{ summary.totalCount | makeComma }}건
				</v-col>
			</v-row>
			
			<v-row
				class="bg-eo rounded-lg text-black font-weight-bold"
			>
				<v-col>
					결제 총액
				</v-col>
				<v-col
					class="text-right"
				>
					{{ summary.paymentTotal | makeComma }}원
				</v-col>
			</v-row>
		</div>
		
		<div
			class="pa-3 bg-white"
			
		>
			<v-card
				v-for="(item, index) in list"
				:key="index"

				elevation="0"
				class="mt-3 mb-3 card-top"
				:to="'/Branch/Payment/' + item.orgSeq"
				
				tile
			>
			
				<div
					v-if="item.viewDt"
					class="border-bottom2 mt-5 pb-2 text-black font-weight-bold"
				>
					{{ item.paymentDate | transDateWeek }}
				</div>
				
				<v-card-text
					class="pa-0"
				>
					<v-row>
						<v-col>
							<div class="text-black text-subtitle-1">{{ item.salesManager }}</div>
							<div class="text-subtitle-2">{{ item.phoneNumber | makeTell }}</div>
						</v-col>
						<v-col>
							<span 
								class="float-right text-subtitle-1 font-weight-bold mt-5" 
								color="success2"
							>{{ item.totAmt | makeComma }} 원</span> 
						</v-col>
					</v-row>
				</v-card-text>
				
				<v-divider></v-divider>
			</v-card>
			
			<v-card
				v-if="items.length <= 0"
				class="mt-3 mb-6 card-top"
				elevation="0"
			>
				<div>
					<v-row
						class="pa-0 ma-0"
					>
						<v-col 
							class="text-caption pa-0 ma-0"
						></v-col>
						<v-col
							cols="7"
							class="pa-0 ma-0"
						>
							<v-divider style="margin-top: 10px;"></v-divider>
						</v-col>
					</v-row>
				</div>
				<v-card-text
					class="text-center mt-10"
				>
					조회된 정보가 없습니다
				</v-card-text>
			</v-card>
		</div>
		
	</div>
</template>

<script>
	import PickerWeeks from '@/components/PickerWeeks.vue'
	export default{
		mame: 'BanchPayment'
		,components: { PickerWeeks }
		,created: function(){
			this.$emit('setProgram', this.program)
			this.getSummary()
		}
		,props: ['user', 'callBack']
		,data: function(){
			return {
				program: {
					title: '영업점 매출'
					,next: {
						title: '월간'
						,do: true
						,callBack: 'weekly'
					}
				}
				,list_date: ''
				,items: []
				,sample: [
					{
						paymentDate: '20201111'
						,salesManager: '홍길동'
						,phoneNumber: '01024892570'
						,totAmt: 123400
					}
					,{
						paymentDate: '20201111'
						,salesManager: '홍길동'
						,phoneNumber: '01024892570'
						,totAmt: 123400
					}
					,{
						paymentDate: '20201109'
						,salesManager: '홍길동'
						,phoneNumber: '01024892570'
						,totAmt: 123400
					}
					,{
						paymentDate: '20201107'
						,salesManager: '홍길동'
						,phoneNumber: '01024892570'
						,totAmt: 123400
					}
				]
				,summary: {
					totalCount: 0
					,paymentTotal: 0
				}
				,date: {
					inquiryEndDate: '20201001'
					,inquiryStartDate : '20201112'
				}
			}
		}
		,computed: {
			list: function(){
				console.log('computed list')
				let self = this
				return this.items.filter(function(item){
					
					if(item.paymentDate != self.list_date){
						self.list_date = item.paymentDate
						item.viewDt = true
					}
					
					return item
				})
			}
		}
		,methods: {
			getData: function(){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/sales/history'
					,request_params: this.date
					,authorize: true
					,callBack: 'getDataResult'
				})
			}
			,getDataResult: function(call){
				if(call.result){
					this.items = call.items.content.content
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
			,getSummary: function(){
				this.$emit('axios', {
					request_type: 'get'
					,request_url: '/sales/summary'
					,request_params: this.date
					,authorize: true
					,callBack: 'getSummaryResult'
				})
			}
			,getSummaryResult: function(call){
				if(call.result){
					this.summary = call.items.content
					this.getData()
				}else{
					this.$emit('alertMsg', 'error', call.items.message)
				}
			}
			,PickerWeeks: function(call){
				console.log('calculate PickerWeeks')
				console.log(call)
			}
		}
		,watch: {
			callBack: {
				deep: true
				,handler: function(call){
					if(call.name == 'weekly'){
						this.$router.push('/Branch/Payment')
					}else if(call.name == 'getSummaryResult'){
						this.getSummaryResult(call)
					}else if(call.name == 'getDataResult'){
						this.getDataResult(call)
					}
				}
			}
		}
	}
</script>

<style>
	.branch.payment .picker {border-top: 1px solid #ddd; border-bottom: 1px solid #ddd; padding: 0px 10px 6px;}
	.branch.payment .border-bottom2 {border-bottom: 2px solid #ddd;}
</style>