<template>
		
		<div
			class="text-center font-weight-bold"
		>
			<v-btn
				text
				class="float-left pa-0 ma-0"
				style="min-width: 0;"
				@click="setDate(false)"
			>
				<v-icon>mdi-chevron-left</v-icon>
			</v-btn>
			
			<span class="d-inline-block pt-2 ">{{ date.year }}년 {{ date.month }}월</span>
			
			<v-btn
				text
				class="float-right  pa-0 ma-0"
				style="min-width: 0;"
				@click="setDate(true)"
				:disabled="disabled"
			>
				<v-icon>mdi-chevron-right</v-icon>
			</v-btn>
				
		</div>
</template>

<script>
	export default{
		name: 'PickerMonth'
		,created: function(){
			const date = new Date()
			
			this.date.year = date.getFullYear() + ''
			this.date.month = ('0' + (date.getMonth() + 1)).slice("-2")
			this.date.day = ('0' + date.getDate()).slice("-2")
			this.date.fullDate = this.date.year + this.date.month + this.date.day
			
			this.date.today = this.date.fullDate
		}
		,props: []
		,data: function(){
			return {
				weeks: ['일', '월', '화', '수', '목', '금']
				,date: {
					year: ''
					,month: ''
					,day: ''
					,weeks: ''
					,week: ''
					,fullDate: ''
					,today: ''
				}
				,disabled: true
			}
		}
		,methods: {
			sprint: function(val, len){
				return ('0' + (val)).slice("-" + len)
			}
			,setDate: function(call){
				if(call){
					this.date.month++
					
					if(this.date.month > 12){
						this.date.month = this.sprint(1, 2)
						this.date.year++
					}else{
						this.date.month = this.sprint(this.date.month, 2)
					}
				}else{
					this.date.month--
					if(this.date.month <= 0){
						this.date.month = this.sprint(12, 2)
						this.date.year--
					}else{
						this.date.month = this.sprint(this.date.month, 2)
					}
				}
				
				this.date.fullDate = this.date.year + this.date.month + this.date.day
				
				if(this.date.fullDate >= this.date.today){
					this.disabled = true
				}else{
					this.disabled = false
				}
			}
		}
		,watch: {
			date: {
				deep: true
				,handler: function(){
					this.$emit('click', this.date)
				}
			}
		}
	}
</script>